import React, { useState } from "react";
import Link from "@react/widget/Link";
import Icon from "@react/widget/Icon";
import { useTranslated, type Locale } from "@i18next/utils";
import Collapse from "@components/widget/Collapse";
import FooterAccordion from "./FooterAccordion";
import FooterCommon from "./FooterCommon";

interface Props extends Record<string, any> {
  currentSite: Page.Site;
  data?: Array<Page.Navigation>;
}

const FooterNav: React.FC<Props> = ({ currentSite, data: navigations, ...props }) => {
  const { t, tr } = useTranslated(currentSite.locale);
  const [level0, setLevel0] = useState<number | null>(0);
  if (!navigations)
    return <></>;
  return (
    <>
      <div className="lg:hidden">
        {navigations?.map((_nav, _index) => !!_nav.children?.length ? (
          <Collapse
            key={`footer-nav-${_index}`}
            classNames={{
              base: "font-semibold group/level0",
              headline: `w-full border-0 flex justify-between items-center cursor-pointer text-left border-split-light border-b py-3`
            }}
            isOpen={level0 === _index}
            headline={(<><span>{_nav.title}</span><Icon className="group-data-[expanded=true]/level0:rotate-180 transition-[transform]" name="chevron-down" /></>)}
            onOpen={(o, e) => setLevel0(o ? _index : null)}
          >
            {_nav.children.map((_n, _i, _a) => {
              return !!_n.children?.length ? (
                <Collapse
                  key={`footer-nav-children-${_i}`}
                  classNames={{
                    base: "font-semibold text-sm group/level1",
                    headline: `w-full border-0 flex gap-2 items-center cursor-pointer text-left py-3`
                  }}
                  isOpen={_a.length === 1}
                  headline={(<><Icon className="group-data-[expanded=true]/level1:hidden" name="plus" /><Icon className="group-data-[expanded=false]/level1:hidden" name="minus" /><span>{_n.title}</span></>)}
                >
                  <ul className="py-1 pl-6 font-normal">
                    {_n.children?.map((n, i) => (n.element?.status !== "disabled") && <li key={`accordion-item-li-${i}`}><Link className="block w-full py-1.5" href={tr(n.uri)}>{n.title}</Link></li>)}
                  </ul>
                </Collapse>
              ) : (
                <Link key={`footer-nav-children-${_i}`} className="block w-full border-split-light border-b py-3 pl-6" href={tr(_n.uri)}>{_n.title}</Link>
              );
            })}
          </Collapse>
        ) : (
          <Link key={`footer-nav-${_index}`} className="block w-full border-split-light border-b py-3 font-semibold" href={tr(_nav.uri)}>{_nav.title}</Link>
        )
        )}
      </div>
      <div className="grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-10 hidden lg:grid [&_li]:opacity-80 [&_li_a:hover]:opacity-100 [&_li_a:hover]:underline [&_li_a:hover]:!text-white [&_li]:font-light [&_dt]:tracking-wide [&_li]:tracking-wide">
        {navigations?.map((_nav, _i) => {
          if (_nav.id === "294945")
            return <FooterAccordion key={`lg-footer-nav-${_i}`} currentSite={currentSite} data={_nav}/>;
          if (["294946", "294949", "294948", "294950", "914731", "914733", "914754", "914746", "914755"].includes(_nav.id))
            return <FooterCommon key={`lg-footer-nav-${_i}`} currentSite={currentSite} data={_nav}/>;
        })}
      </div>
    </>
  );
};

export default FooterNav;